import React  from 'react';
import { Container } from 'reactstrap';
import trainers2022Mobile from '../../images/yoga/trainers2022-mobile.png';
import trainers2022 from '../../images/yoga/trainers2022.png';
import advantagesMobile from '../../images/newyear2020/advantages-mobile.png';
import advantages from '../../images/newyear2020/advantages.png';

const Yoga8 = () => (
    <section className="yoga8">

        <Container className="text-center">
            <h2>Wybierając dowolny pakiet treningowy otrzymasz dostęp do ćwiczeń z wszystkimi trenerami</h2>     
            <img src={trainers2022Mobile} className="mb-5 d-md-none img-fluid" alt=""/>
            <img src={trainers2022} className="mb-5 d-none d-md-block img-fluid" alt=""/>

            <img src={advantagesMobile} className="img-fluid d-md-none" alt=""/>
            <img src={advantages} className="img-fluid d-none d-md-block" alt=""/>       
        </Container>
    </section>
);

export default Yoga8;
