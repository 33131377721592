import React  from 'react';
import { Container, Button } from 'reactstrap';
import { scroller } from 'react-scroll';

const Yoga1 = () => (    
    <section className="yoga1">
        <Container>   

            <div className="tag">
                <strong>NOWOŚĆ</strong><span>treningi już dostępne</span>
            </div>

            <div className="title">
                <h1>Yoga</h1>
                <h2>w Diet & Training by Ann</h2>
            </div>
            <Button
                color="primary"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >
                Uzyskaj dostęp
            </Button>
            
        </Container>
    </section>
);

export default Yoga1;
