import React  from 'react';
import { Container, Button } from 'reactstrap';
import { scroller } from 'react-scroll';

import iconFlower from '../../images/yoga/icon_flower.svg';

const Yoga3 = () => (
    <section className="yoga3">
        <Container className="text-center">
            <img src={iconFlower} alt="" />
            <h2>Zyskaj wewnętrzną równowagę, <br/>rozciągnij ciało i wyrzeźb mięśnie</h2>

            <Button
                color="primary"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >
                Uzyskaj dostęp
            </Button>

        </Container>    
    </section>
);

export default Yoga3;
