import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import asana2 from '../../images/yoga/na_glowie.png';
import training1 from '../../images/yoga/training_1.jpg';
import training2 from '../../images/yoga/training_2.jpg';
import training3 from '../../images/yoga/training_3.jpg';

import ScrollAnimation from 'react-animate-on-scroll';

import { scroller } from 'react-scroll';

const Yoga5 = () => (
    <section className="yoga5">

        <div className="bgcolor">
            <Container className="text-center">
                <div className="asana2">
                    <img src={asana2} className="img-fluid" alt="" />
                </div>

                <h2>Trzy programy treningowe na start o&nbsp;trudności</h2>
                <h3><span className="tag">easy</span> oraz <span className="tag">medium</span></h3>

                <Row>
                    <Col md="4">
                        <div className="box">
                            <img src={training2} className="img-fluid" alt="" />
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce
                                className="content"
                            >
                                <div><span className="big">YOGA</span><br/>FOR BEGINNERS</div>
                            </ScrollAnimation>
                        </div>
                        <p className="lead">Plan idealny dla osób, które nigdy nie praktykowały yogi</p>
                        <p>Dedykowany osobom początkującym, oparty na podstawowych pozycjach yogi - asanach. Nastawiony na uelastycznienie ciała oraz redukcję skutków stresu. Z tym programem rozluźnisz wszystkie mięśnie, trwale poprawisz samopoczucie i komfort codziennego funkcjonowania.</p>
                    </Col>
                    <Col md="4">
                        <div className="box">
                            <img src={training1} className="img-fluid" alt="" />
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce
                                className="content"
                            >
                                <div>FAT BURNING<br/><span className="big">YOGA</span></div>
                            </ScrollAnimation>
                        </div>
                        <p className="lead">Spal tkankę tłuszczową i zwiększ elastyczność swojego ciała</p>
                        <p>Oparcie praktyki yogi na energicznych sekwencjach pozwala wzmocnić, ustabilizować i ujędrnić całe ciało. Ten program przyspieszy Twój metabolizm i sprawi, że popłynie niejedna kropelka potu. Przekonasz się, że yoga to coś więcej niż samo rozciąganie!</p>
                    </Col>
                    <Col md="4">
                        <div className="box">
                            <img src={training3} className="img-fluid" alt="" />
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce
                                className="content"
                            >
                                <div>RELAXATION<br/>& STRESS<br/>RELIEF</div>
                            </ScrollAnimation>
                        </div>
                        <p className="lead">Odpręż ciało i uwolnij umysł od nagromadzonego stresu</p>
                        <p>Program oparty na łagodnej praktyce, mający na celu rozluźnienie ciała i przywrócenie swobodnego oddechu. Ważnym elementem w trakcie praktyki z tym programem jest likwidacja napięć w ciele, szczególnie w okolicy kręgosłupa.</p>
                    </Col>                    
                </Row>    

                <Button
                    color="white"
                    onClick={() =>
                        scroller.scrollTo('buy', {
                            duration: 1000,
                            smooth: true,
                            offset: -60,
                        })
                    }
                >
                    Uzyskaj dostęp
                </Button>  
            </Container>
        </div>
        
    </section>
);

export default Yoga5;
