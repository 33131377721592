import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { scroller } from 'react-scroll';
import ewelina from '../../images/yoga/ewelina.png';
import { USER_COUNT } from '../../constants';

const Yoga7 = () => (
    <section className="yoga7">

        <Container className="text-center">
        <h2>Dołącz do ponad <br/>{USER_COUNT} użytkowniczek!</h2>            
        </Container>

        <div className="social-proof">
            <Container>
                <Row>
                    <Col md={{size: 10, offset: 1}}>
                        <div className="box">
                            <span className="tag">TWOJA TRENERKA</span>
                            <h3>Ewelina Godlewska</h3>
                            <p>Dyplomowana nauczycielka yogi z ponad 10-letnin doświadczeniem. Z wykształcenia psycholog, life coach i instruktor rekreacji ruchowej o specjalności ćwiczenia psychofizyczne w oparciu o system hatha yogi.</p>
                            <p>Współautorka książki „Po pierwsze joga”. Trener Healthy Teamu Anny Lewandowskiej.</p>
                            <img src={ewelina} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container className="text-center"> 
            <Button
                color="primary"
                className="mt-md-5"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >Uzyskaj dostęp</Button>
        </Container>
    
    </section>
);

export default Yoga7;
