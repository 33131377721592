import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";

import Yoga1 from "../components/Yoga/Yoga1";
import Yoga2 from "../components/Yoga/Yoga2";
import Yoga3 from "../components/Yoga/Yoga3";
import Yoga4 from "../components/Yoga/Yoga4";
import Yoga5 from "../components/Yoga/Yoga5";
import Yoga6 from "../components/Yoga/Yoga6";
import Yoga7 from "../components/Yoga/Yoga7";
import Yoga8 from "../components/Yoga/Yoga8";

import SaleProductsList from "../components/SaleProduct/SaleProductsList";
import SaleProductsGroup from "../SaleProduct/SaleProductsGroup";

import { graphql} from "gatsby";

const Page = ({ data }) => {

    const productsGroups = [
        new SaleProductsGroup(
            'landing:main:workout',
            'Trening',
            'Dostęp do wszystkich planów treningowych',
            'black'
        ),
    ];

    return (
        <Layout page="yoga" showMenu={true}>
            <Seo />
            
            <Yoga1 background={data.background.childImageSharp.gatsbyImageData} />
            <Yoga2 />
            <Yoga3 />
            <Yoga4 />
            <Yoga5 />
            <Yoga6 />
            <Yoga7 />
            <Yoga8 />
           
            <SaleProductsList productsGroups={productsGroups} source="homepage" />

        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        background: file(relativePath: { eq: "black-friday/header_bg.jpg" }) {
            ...fluidImage1920
        }
        background2: file(relativePath: { eq: "black-friday/yoga_bg2.jpg" }) {
            ...fluidImage1920
        }
        background3: file(relativePath: { eq: "black-friday/yoga_bg4.jpg" }) {
            ...fluidImage960
        }
        background7: file(relativePath: { eq: "black-friday/promocja_bg.jpg" }) {
            ...fluidImage1920
        }

        
    }
`;
