import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import header from '../../images/yoga/header.png';

import icon_asany from '../../images/yoga/icon_asany.svg';
import icon_pranayama from '../../images/yoga/icon_pranayama.svg';

import app from '../../images/yoga/app.png';


const Yoga2 = (props) => (
    <section className="yoga2">
        <Container className="text-center">   
            <div className="asana1">
                <img src={header} alt="" className="img-fluid " />
            </div>

            <Row>
                <Col xs="6" md={{ size: 4, offset: 2 }}>
                    <div className="round">
                        <img src={icon_asany} className="img-fluid" alt="" />
                    </div>
                    <p><strong>praktyka asan</strong><br/>
                    aspekt fizyczny jogi</p>
                </Col>
                {/* <Col md="2"><span className="big">+</span></Col> */}
                <Col xs="6" md="4">
                    <div className="round">
                        <img src={icon_pranayama} className="img-fluid" alt="" />
                    </div>
                    
                    <p><strong>pranayama</strong><br/>
                    kontrola oddechów</p>
                </Col>
            </Row>
            <img src={app} className="img-fluid mt-5 mt-md-4" alt="" />
        </Container>
    </section>
);

export default Yoga2;
