import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import icon1 from '../../images/yoga/icon_yoga_1_b.svg';
import icon2 from '../../images/yoga/icon_yoga_2_b.svg';
import icon3 from '../../images/yoga/icon_yoga_3_b.svg';
import icon4 from '../../images/yoga/icon_yoga_4_b.svg';

import iconTime from '../../images/yoga/icon_time_b.svg';
import iconVoice from '../../images/yoga/icon_voice_b.svg';
import iconQuality from '../../images/yoga/icon_quality_b.svg';
import iconOffline from '../../images/yoga/icon_offline_b.svg';

const Yoga4 = () => (
    <section className="yoga4">
        <Container className="text-center">
            <Row className="mb-md-5">
                <Col xs="6" md="3">
                    <div className="round">
                        <img src={icon1} className="img-fluid" alt="" />
                    </div>
                    <p>Ponad 400<br/>ćwiczeń</p>
                </Col>
                <Col xs="6" md="3">
                    <div className="round">
                        <img src={icon2} className="img-fluid" alt="" />
                    </div>
                    <p>3 programy<br/>treningowe</p>
                </Col>
                <Col xs="6" md="3">
                    <div className="round">
                        <img src={icon3} className="img-fluid" alt="" />
                    </div>
                    <p>Różne poziomy<br/>trudności</p>
                </Col>
                <Col xs="6" md="3">
                    <div className="round">
                        <img src={icon4} className="img-fluid" alt="" />
                    </div>
                    <p>Certyfikowana<br/>instruktorka</p>
                </Col>
            </Row>

            <Row>
                <Col xs="6" md="3">
                    <div className="round2">
                        <img src={iconTime} className="img-fluid" alt="" />
                    </div>
                    <p>Elastyczny wybór<br/>czasu trwania</p>
                </Col>
                <Col xs="6" md="3">
                    <div className="round2">
                        <img src={iconOffline} className="img-fluid" alt="" />
                    </div>
                    <p>Dostęp offline</p>
                </Col>
                <Col xs="6" md="3">
                    <div className="round2">
                        <img src={iconQuality} className="img-fluid" alt="" />
                    </div>
                    <p>Wysoka jakość<br/>nagrań</p>
                </Col>
                <Col xs="6" md="3">
                    <div className="round2">
                        <img src={iconVoice} className="img-fluid" alt="" />
                    </div>
                    <p>Instrukcje głosowe</p>
                </Col>
            </Row>

           

        </Container>
    
        
    </section>
);

export default Yoga4;
